
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as abuseReportswVPD6Xy5TuMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93NKNtfyuOSQMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as editor7pe3JwjeA9Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/blog/editor.vue?macro=true";
import { default as index7xGNvj0ciCMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/index.vue?macro=true";
import { default as moderationn5t2JEE1HzMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/moderation.vue?macro=true";
import { default as pendingBansFlrjfDWKknMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/pendingBans.vue?macro=true";
import { default as profilesEwMfKzdaN3Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/profiles.vue?macro=true";
import { default as expensesXNeaKnxK6QMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexox4z9BXV4DMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/timesheets/index.vue?macro=true";
import { default as overview45eQ6pyExMMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingck1d9j7j9fMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingi1cY6IMkBdMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/translations/missing.vue?macro=true";
import { default as usersM6EwCZKRrkMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/users.vue?macro=true";
import { default as apiN3kG7VdSH7Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/api.vue?macro=true";
import { default as _91slug_93EZ4jOmPAPAMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/blog/[slug].vue?macro=true";
import { default as index8UYba8qLe5Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_939C1ueZwcUbMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_937kB1nwepXKMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminh6Yx7FflFJMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/census/admin.vue?macro=true";
import { default as indextA02qdUfC4Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/census/index.vue?macro=true";
import { default as contactnoYhO517y9Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/contact.vue?macro=true";
import { default as design5Fr7iGGvAuMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/design.vue?macro=true";
import { default as englishi9mQqZRoedMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/english.vue?macro=true";
import { default as faqivPAOUXnZwMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/faq.vue?macro=true";
import { default as inclusiveRctlbYZFpxMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/inclusive.vue?macro=true";
import { default as index_45homeldxOWzkJfpMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/index-home.vue?macro=true";
import { default as index_45localejuuivQMfPFMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/index-locale.vue?macro=true";
import { default as langswitchUm4fLaHrCoMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/langswitch.vue?macro=true";
import { default as licenseSohapKsRbWMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/license.vue?macro=true";
import { default as academicWFYwYfsqe5Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/links/academic.vue?macro=true";
import { default as indexeV84dFNBqMMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/links/index.vue?macro=true";
import { default as mediaOpf392SCpWMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/links/media.vue?macro=true";
import { default as translinguisticsQuP0kUwuROMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/links/translinguistics.vue?macro=true";
import { default as zineOZzff8ei1dMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/links/zine.vue?macro=true";
import { default as namesESfq8SEPHkMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/names.vue?macro=true";
import { default as indexYL8ZRBDKzKMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/nouns/index.vue?macro=true";
import { default as templatesXXwG5FLCPkMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/nouns/templates.vue?macro=true";
import { default as people9beFOjh3ffMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/people.vue?macro=true";
import { default as privacyHjx5TXRvVaMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/privacy.vue?macro=true";
import { default as _91username_93a7pRoJ3XeYMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_930gfET0ZrDFMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/profile/card-[username].vue?macro=true";
import { default as editorThuNVQAaWFMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/profile/editor.vue?macro=true";
import { default as anypP9UNC9FBwMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/any.vue?macro=true";
import { default as asktEn0Q9U47rMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/ask.vue?macro=true";
import { default as avoidingAgAqBdrS4NMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/avoiding.vue?macro=true";
import { default as index0LIl8to2n8Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/index.vue?macro=true";
import { default as mirrorvQxgPwhjY1Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/mirror.vue?macro=true";
import { default as pronounZMYVIRpFCDMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/pronoun.vue?macro=true";
import { default as sources0NYVyHgSmwMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/sources.vue?macro=true";
import { default as team7PSpBnshAIMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/team.vue?macro=true";
import { default as terminology1xE5iBq3n0Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/terminology.vue?macro=true";
import { default as termsWBalEO8HeWMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/terms.vue?macro=true";
import { default as user5hTqO2tjoMMeta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/user.vue?macro=true";
import { default as workshopsTV7Fzewmq6Meta } from "/home/admin/www/pronoms.fr/release/20250119182130/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportswVPD6Xy5TuMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin-blog-editor",
    path: "/admin/blog/editor",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/blog/editor.vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansFlrjfDWKknMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiN3kG7VdSH7Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93EZ4jOmPAPAMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: index8UYba8qLe5Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_939C1ueZwcUbMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_937kB1nwepXKMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminh6Yx7FflFJMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indextA02qdUfC4Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactnoYhO517y9Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishi9mQqZRoedMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqivPAOUXnZwMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveRctlbYZFpxMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/inclusive.vue")
  },
  {
    name: "index-home",
    path: "/index-home",
    meta: index_45homeldxOWzkJfpMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/index-home.vue")
  },
  {
    name: "index",
    path: "/index-locale",
    meta: index_45localejuuivQMfPFMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/index-locale.vue")
  },
  {
    name: "langswitch",
    path: "/langswitch",
    meta: langswitchUm4fLaHrCoMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/langswitch.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicWFYwYfsqe5Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexeV84dFNBqMMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaOpf392SCpWMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsQuP0kUwuROMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineOZzff8ei1dMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesESfq8SEPHkMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexYL8ZRBDKzKMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesXXwG5FLCPkMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: people9beFOjh3ffMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyHjx5TXRvVaMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93a7pRoJ3XeYMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_930gfET0ZrDFMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorThuNVQAaWFMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anypP9UNC9FBwMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: asktEn0Q9U47rMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingAgAqBdrS4NMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index0LIl8to2n8Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorvQxgPwhjY1Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounZMYVIRpFCDMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources0NYVyHgSmwMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: team7PSpBnshAIMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminology1xE5iBq3n0Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsWBalEO8HeWMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: user5hTqO2tjoMMeta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsTV7Fzewmq6Meta || {},
    component: () => import("/home/admin/www/pronoms.fr/release/20250119182130/pages/workshops.vue")
  }
]